.control-container {
    position: relative;
    margin-bottom: 30px;
}

.control-label {
    z-index: 1;
    position: absolute;
    pointer-events: none;
    top: 10px;
    font-size: 22px;
    transition: font-size 0.2s ease-in-out, top 0.2s ease-in-out, color 0.2s ease-in-out;
}

.control.is-text.is-filled .control-label,
.control.is-text.is-active .control-label {
    top: -15px;
    font-size: 16px;
}

.control.is-active .control-label {
    color: blue;
}

.control .control-input {
    border: none;
    outline: none;
    font-size: 22px;
    width: 100%;
    padding: 10px 0;
    transition: border 0.2s ease-in-out;
}


.control.is-text .control-input {
    border-bottom: solid 1px #4a4a4a;
}

.control.is-text .control.is-active .control-input {
    border-bottom: solid 1px blue;
}

.control.is-textarea {
    border: solid 1px #4a4a4a;
    border-radius: 3px;
}

.control.control.is-textarea.is-active {
    border-color: blue;
}

.control.is-textarea .control-label {
    position: relative;
    margin-bottom: 10px;
    display: block;
}

.control.is-textarea {
    padding: 10px;
}

.control.is-textarea .control-input {
    min-height: 200px;
    position: relative;
    margin: auto;
    margin-top: 10px;
    border: none;
}

.control-buttons {
    display: flex;
}

.control-button {
    font-size: 22px;
    padding: 20px 10px;
    background-color: none;
    border: 1px transparent;
    width: 25%;
    margin-right: 20px;
    border-radius: 3px;
    outline: none;
    transition: background-color 0.2s ease-in-out;

}

.control-button:hover {
    cursor: pointer;
}

.control-button.is-send {
    background-color: #F8BEEC;
    color: #fff;
}

.control-button.is-send:hover,
.control-button.is-send:active,
.control-button.is-send:focus {
    background-color: #E573C5;
}

.control-button.is-reset {
    background-color: #fff;
}

.control-button.is-reset:hover,
.control-button.is-reset:active,
.control-button.is-reset:focus {
    background-color: #f0f0f0;
}

.control-message {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    transition: color 0.2s ease-in-out;
}

.control-container.is-invalid .control-message,
.control.is-invalid .control-message {
    color: red;
}

.shake-0 {
    animation: shake-0 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes shake-0 {

    10%,
    90% {
        transform: translateX(-1px);
    }

    20%,
    80% {
        transform: translateX(2px);
    }

    30%,
    50%,
    70% {
        transform: translateX(-4px);
    }

    40%,
    60% {
        transform: translateX(4px);
    }
}

.shake-1 {
    animation: shake-1 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes shake-1 {

    10%,
    90% {
        transform: translateX(-1px);
    }

    20%,
    80% {
        transform: translateX(2px);
    }

    30%,
    50%,
    70% {
        transform: translateX(-4px);
    }

    40%,
    60% {
        transform: translateX(4px);
    }
}

.contact-warning {
    margin-top: 20px;
    white-space: pre-line;
    font-size: 18px;
    color: #4a4a4a;
    line-height: 1.2;
}