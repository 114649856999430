.hero {
    background-color: #9E29A8;
    padding: 20px;
    margin-bottom: 60px;
}

.hero-container {
    max-width: 1080px;
    margin: auto;
}

.hero .icon {
    transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out, stroke-width 0.2s ease-in-out;
}

.hero .icon-mgda {
    stroke: none;
    stroke-width: 0;
    fill: #fff;
}

.hero-images a {
    outline: none;
}

.hero .icon-mgda:hover,
.hero-images a:focus .icon-mgda {
    stroke-width: 2px;
    stroke: #272727;
    fill: none;
}

.hero-images {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.hero-images-social {
    margin-left: auto;
}

.hero-images-social .icon {
    fill: #fff;
    width: 30px;
    height: auto;
    margin-left: 15px;
}

.hero-images-social a:focus {
    outline: none;
}

.hero-images-social .icon:hover,
.hero-images-social a:focus .icon {
    fill: #272727;
}

.hero-links {
    display: flex;
    flex-wrap: wrap;
}

.hero-link {
    font-size: 20px;
    margin-right: 15px;
    text-decoration: none;
    color: #fff;
    transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    border-bottom: solid 1px transparent;
    padding-bottom: 5px;
}


@media(max-width: 397px) {
    .hero-link {
        font-size: 16px;
    }   
}

@media(max-width: 337px) {
    .hero-link {
        font-size: 14px;
    }   
}


.hero-link:hover,
.hero-link:focus {
    outline: none;
    color: #272727;
    border-color: #272727;
}