.hero {
    background-color: #9E29A8;
    padding: 20px;
    margin-bottom: 60px;
}

.hero-container {
    max-width: 1080px;
    margin: auto;
}

.hero .icon {
    -webkit-transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out, stroke-width 0.2s ease-in-out;
    transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out, stroke-width 0.2s ease-in-out;
}

.hero .icon-mgda {
    stroke: none;
    stroke-width: 0;
    fill: #fff;
}

.hero-images a {
    outline: none;
}

.hero .icon-mgda:hover,
.hero-images a:focus .icon-mgda {
    stroke-width: 2px;
    stroke: #272727;
    fill: none;
}

.hero-images {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.hero-images-social {
    margin-left: auto;
}

.hero-images-social .icon {
    fill: #fff;
    width: 30px;
    height: auto;
    margin-left: 15px;
}

.hero-images-social a:focus {
    outline: none;
}

.hero-images-social .icon:hover,
.hero-images-social a:focus .icon {
    fill: #272727;
}

.hero-links {
    display: flex;
    flex-wrap: wrap;
}

.hero-link {
    font-size: 20px;
    margin-right: 15px;
    text-decoration: none;
    color: #fff;
    -webkit-transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    border-bottom: solid 1px transparent;
    padding-bottom: 5px;
}


@media(max-width: 397px) {
    .hero-link {
        font-size: 16px;
    }   
}

@media(max-width: 337px) {
    .hero-link {
        font-size: 14px;
    }   
}


.hero-link:hover,
.hero-link:focus {
    outline: none;
    color: #272727;
    border-color: #272727;
}
.head {
    overflow: hidden;
    max-width: 1080px;
    margin: auto;
    position: relative;
    -webkit-transition: height 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
}

.row {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-bottom: 80px;
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.row.is-left .right {
    display: none;
}

.row.is-right .left {
    display: none;
}

.row .left {
    margin-right: 20px;
}

.row .icon {
    width: 100px;
    height: auto;
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
}

@media(max-width: 622px) {
    .row .left {
        margin-right: 15px;
    }

    .row .icon {
        -webkit-transform: translateY(10px);
                transform: translateY(10px);
    }
}

.row .title,
.row .message {
    color: #272727;
}

.row .title {
    font-size: 24px;
    font-weight: bold;
}

.row .message {
    font-size: 18px;
}

.row .link {
    font-size: 18px;
    text-decoration: none;
    border-bottom: solid 1px transparent;
    -webkit-transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    width: auto;
    color: #6AA0E8;
    padding-bottom: 5px;
}

.row .link:hover,
.row .link:focus {
    outline: none;
    border-color: #2565BA;
    color: #2565BA;
}

.message-container,
.link-container {
    padding-bottom: 5px;
    opacity: 1;
    overflow: hidden;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}

.title-container {
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.row.is-left .title-container {
    -webkit-transform: translate(-20px, -20px);
            transform: translate(-20px, -20px);
}

.row.is-left .link-container {
    -webkit-transform: translate(-20px, 25px);
            transform: translate(-20px, 25px);
}

.row.is-right .middle {
    margin-left: auto;
    margin-right: 30px;
}

.row.is-right .title-container,
.row.is-right .message-container,
.row.is-right .link-container {
    text-align: right;
}

.row.is-right .title-container {
    -webkit-transform: translate(20px, -20px);
            transform: translate(20px, -20px);
}

.row.is-right .link-container {
    -webkit-transform: translate(20px, 25px);
            transform: translate(20px, 25px);
}

.row.is-right .icon {
    margin-right: 5px;
}

.row.is-left .icon {
    margin-left: 5px;
}

.row.is-title .message-container,
.row.is-title .link-container {
    opacity: 0;
}

.row.is-title .title-container {
    -webkit-transform: translate(0, 25px);
            transform: translate(0, 25px);
}

.row.is-hidden {
    opacity: 0;
}

.row.is-hidden .title-container {
    -webkit-transform: translate(0, 25px);
            transform: translate(0, 25px);
    opacity: 0;
}

.row.is-hidden .message-container,
.row.is-hidden .link-container {
    opacity: 0;
}
.day {
    display: flex;
    align-items: baseline;
    position: relative;
    overflow: hidden;
    min-height: 110px;
    -webkit-transition: height 0.2s ease-in-out;
    transition: height 0.2s ease-in-out;
}

.day-date {
    display: inline-block;
    width: 70px;
    height: 70px;
    background-color: #F8BEEC;
    text-align: center;
    border-radius: 70px;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-right: 30px;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
}

.day a:focus {
    outline: none;
}

.day-date:hover,
.day a:focus .day-date {
    background-color: #E573C5;
    cursor: pointer;
}

.day.is-active .day-date {
    background-color: #E573C5;
}

.day-date-label {
    color: #fff;
    font-size: 30px;
    line-height: 70px;
}

.line {
    position: absolute;
    display: inline-block;
    height: 100%;
    width: 2px;
    background-color: #C8C8C8;
    left: 35px;
    top: 90px;
}

.day-contents-expanded {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.day-contents-title {
    width: 100%;
    font-size: 30px;
    opacity: 0.3;
}

.day-contents-body {
    display: flex;
    flex-wrap: nowrap;
}

.day-contents-address-content {
    white-space: pre-wrap;
}

.day-contents-schedule {
    flex-grow: 1;
}

.day-contents-address {
    margin-right: 20px;
    min-width: 300px;
}

.day-contents-address,
.day-contents-schedule {
    font-size: 20px;
}

@media(max-width: 453px) {

    .schedule-time,
    .schedule-name {
        display: block;
    }
}

.day-contents-address-content {
    font-style: italic;
}

.schedule-row {
    margin-bottom: 10px;
}

.schedule-time {
    display: inline-block;
    min-width: 136px;
    font-weight: bold;
    margin-right: 20px;
    white-space: nowrap;
}

.day-contents-address-link {
    display: block;
    font-size: 20px;
    text-decoration: none;
    -webkit-transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    border-bottom: solid 1px transparent;
    padding-bottom: 5px;
    color: #6AA0E8;
}

.day-contents-address-link:hover,
.day-contents-address-link:focus {
    outline: none;
    border-color: #2565BA;
    color: #2565BA;
}

.day-contents-collapsed-title,
.day-contents-collapsed-sub-title {
    opacity: 0.3;
}

.day-contents-collapsed-title {
    font-size: 22px;
}

.day-contents-collapsed-sub-title {
    font-size: 18px;
}

.day-contents-collapsed,
.day-contents-expanded {
    overflow: hidden;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.day-contents-collapsed {
    margin-top: -10px;
}

.day-contents-expanded {
    padding-bottom: 10px;
}

.day.is-active .day-contents-collapsed,
.day.is-inactive .day-contents-expanded {
    opacity: 0;
    height: 0;
}

.schedule-row.is-private {
    opacity: 0.3;
}
.control-container {
    position: relative;
    margin-bottom: 30px;
}

.control-label {
    z-index: 1;
    position: absolute;
    pointer-events: none;
    top: 10px;
    font-size: 22px;
    -webkit-transition: font-size 0.2s ease-in-out, top 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: font-size 0.2s ease-in-out, top 0.2s ease-in-out, color 0.2s ease-in-out;
}

.control.is-text.is-filled .control-label,
.control.is-text.is-active .control-label {
    top: -15px;
    font-size: 16px;
}

.control.is-active .control-label {
    color: blue;
}

.control .control-input {
    border: none;
    outline: none;
    font-size: 22px;
    width: 100%;
    padding: 10px 0;
    -webkit-transition: border 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
}


.control.is-text .control-input {
    border-bottom: solid 1px #4a4a4a;
}

.control.is-text .control.is-active .control-input {
    border-bottom: solid 1px blue;
}

.control.is-textarea {
    border: solid 1px #4a4a4a;
    border-radius: 3px;
}

.control.control.is-textarea.is-active {
    border-color: blue;
}

.control.is-textarea .control-label {
    position: relative;
    margin-bottom: 10px;
    display: block;
}

.control.is-textarea {
    padding: 10px;
}

.control.is-textarea .control-input {
    min-height: 200px;
    position: relative;
    margin: auto;
    margin-top: 10px;
    border: none;
}

.control-buttons {
    display: flex;
}

.control-button {
    font-size: 22px;
    padding: 20px 10px;
    background-color: none;
    border: 1px transparent;
    width: 25%;
    margin-right: 20px;
    border-radius: 3px;
    outline: none;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;

}

.control-button:hover {
    cursor: pointer;
}

.control-button.is-send {
    background-color: #F8BEEC;
    color: #fff;
}

.control-button.is-send:hover,
.control-button.is-send:active,
.control-button.is-send:focus {
    background-color: #E573C5;
}

.control-button.is-reset {
    background-color: #fff;
}

.control-button.is-reset:hover,
.control-button.is-reset:active,
.control-button.is-reset:focus {
    background-color: #f0f0f0;
}

.control-message {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}

.control-container.is-invalid .control-message,
.control.is-invalid .control-message {
    color: red;
}

.shake-0 {
    -webkit-animation: shake-0 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake-0 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

@-webkit-keyframes shake-0 {

    10%,
    90% {
        -webkit-transform: translateX(-1px);
                transform: translateX(-1px);
    }

    20%,
    80% {
        -webkit-transform: translateX(2px);
                transform: translateX(2px);
    }

    30%,
    50%,
    70% {
        -webkit-transform: translateX(-4px);
                transform: translateX(-4px);
    }

    40%,
    60% {
        -webkit-transform: translateX(4px);
                transform: translateX(4px);
    }
}

@keyframes shake-0 {

    10%,
    90% {
        -webkit-transform: translateX(-1px);
                transform: translateX(-1px);
    }

    20%,
    80% {
        -webkit-transform: translateX(2px);
                transform: translateX(2px);
    }

    30%,
    50%,
    70% {
        -webkit-transform: translateX(-4px);
                transform: translateX(-4px);
    }

    40%,
    60% {
        -webkit-transform: translateX(4px);
                transform: translateX(4px);
    }
}

.shake-1 {
    -webkit-animation: shake-1 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake-1 0.82s cubic-bezier(.36, .07, .19, .97) both;
}

@-webkit-keyframes shake-1 {

    10%,
    90% {
        -webkit-transform: translateX(-1px);
                transform: translateX(-1px);
    }

    20%,
    80% {
        -webkit-transform: translateX(2px);
                transform: translateX(2px);
    }

    30%,
    50%,
    70% {
        -webkit-transform: translateX(-4px);
                transform: translateX(-4px);
    }

    40%,
    60% {
        -webkit-transform: translateX(4px);
                transform: translateX(4px);
    }
}

@keyframes shake-1 {

    10%,
    90% {
        -webkit-transform: translateX(-1px);
                transform: translateX(-1px);
    }

    20%,
    80% {
        -webkit-transform: translateX(2px);
                transform: translateX(2px);
    }

    30%,
    50%,
    70% {
        -webkit-transform: translateX(-4px);
                transform: translateX(-4px);
    }

    40%,
    60% {
        -webkit-transform: translateX(4px);
                transform: translateX(4px);
    }
}

.contact-warning {
    margin-top: 20px;
    white-space: pre-line;
    font-size: 18px;
    color: #4a4a4a;
    line-height: 1.2;
}
.policy {
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
}

.policy-image {
    width: 100px;
    height: 110px;
    margin-right: 30px;
}

.policy-info {
    flex-grow: 1;
}

.policy-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.policy-message {
    font-size: 18px;
    margin-bottom: 10px;
}

.policy-link {
    font-size: 20px;
    text-decoration: none;
    -webkit-transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    border-bottom: solid 1px transparent;
    color: #6AA0E8;
    padding-bottom: 5px;
}

.policy-link:hover,
.policy-link:focus {
    outline: none;
    border-color: #2565BA;
    color: #2565BA;
}

.content-item {
    width: 275px;
    word-break: break-word;
    margin-bottom: 10px;
}

.content-item:last-child {
    margin-bottom: 0;
}

@media(max-width: 449px) {
    .content-item {
        width: 170px;
    }

    .policy-title {
        font-size: 18px;
    }

    .policy-link {
        font-size: 16px;
    }

    .policy-image {
        width: 50px;
        height: 60px;
    }
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  margin: 0;
  margin-bottom: 50px;
}

body,
input,
textarea,
button {
  font-family: 'Poppins', sans-serif;
}

.body-block {
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  margin: 0;
}

.body-block.is-active {
  margin: auto;
  margin-top: 50px;
  max-width: 780px;
  padding: 0 5%;
}

.body-block.is-active {
  opacity: 1;
  height: auto;
}
