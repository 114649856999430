.day {
    display: flex;
    align-items: baseline;
    position: relative;
    overflow: hidden;
    min-height: 110px;
    transition: height 0.2s ease-in-out;
}

.day-date {
    display: inline-block;
    width: 70px;
    height: 70px;
    background-color: #F8BEEC;
    text-align: center;
    border-radius: 70px;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-right: 30px;
    transition: background-color 0.2s ease-in-out;
}

.day a:focus {
    outline: none;
}

.day-date:hover,
.day a:focus .day-date {
    background-color: #E573C5;
    cursor: pointer;
}

.day.is-active .day-date {
    background-color: #E573C5;
}

.day-date-label {
    color: #fff;
    font-size: 30px;
    line-height: 70px;
}

.line {
    position: absolute;
    display: inline-block;
    height: 100%;
    width: 2px;
    background-color: #C8C8C8;
    left: 35px;
    top: 90px;
}

.day-contents-expanded {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.day-contents-title {
    width: 100%;
    font-size: 30px;
    opacity: 0.3;
}

.day-contents-body {
    display: flex;
    flex-wrap: nowrap;
}

.day-contents-address-content {
    white-space: pre-wrap;
}

.day-contents-schedule {
    flex-grow: 1;
}

.day-contents-address {
    margin-right: 20px;
    min-width: 300px;
}

.day-contents-address,
.day-contents-schedule {
    font-size: 20px;
}

@media(max-width: 453px) {

    .schedule-time,
    .schedule-name {
        display: block;
    }
}

.day-contents-address-content {
    font-style: italic;
}

.schedule-row {
    margin-bottom: 10px;
}

.schedule-time {
    display: inline-block;
    min-width: 136px;
    font-weight: bold;
    margin-right: 20px;
    white-space: nowrap;
}

.day-contents-address-link {
    display: block;
    font-size: 20px;
    text-decoration: none;
    transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
    border-bottom: solid 1px transparent;
    padding-bottom: 5px;
    color: #6AA0E8;
}

.day-contents-address-link:hover,
.day-contents-address-link:focus {
    outline: none;
    border-color: #2565BA;
    color: #2565BA;
}

.day-contents-collapsed-title,
.day-contents-collapsed-sub-title {
    opacity: 0.3;
}

.day-contents-collapsed-title {
    font-size: 22px;
}

.day-contents-collapsed-sub-title {
    font-size: 18px;
}

.day-contents-collapsed,
.day-contents-expanded {
    overflow: hidden;
    transition: opacity 0.2s ease-in-out;
}

.day-contents-collapsed {
    margin-top: -10px;
}

.day-contents-expanded {
    padding-bottom: 10px;
}

.day.is-active .day-contents-collapsed,
.day.is-inactive .day-contents-expanded {
    opacity: 0;
    height: 0;
}

.schedule-row.is-private {
    opacity: 0.3;
}